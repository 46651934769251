import React from 'react'
import {ChartLabel, FlexibleWidthXYPlot, XAxis, YAxis, VerticalGridLines, HorizontalGridLines, LineSeries} from 'react-vis'
import 'react-vis/dist/style.css';

const Chart = (props) => {
    const dataArr = props.data ? props.data.map((item) => {
        return {
            x: item.timestamp.substring(11,13),
            y: item.readings.pm25_one_hourly[props.sourceLocation]
        }
    }) : null

    // const {yMin, yMax} = dataArr ? dataArr.reduce((acc, row) => ({
    //     yMax: acc ? Math.max(acc.yMax, row.y) : null,
    //     yMin: acc ? Math.min(acc.yMin, row.y) : null
    //     }), {yMin: Infinity, yMax: -Infinity}) : null
    // console.log(yMin + ', ' + yMax)
        
    const yMax = 160
    return (
        <FlexibleWidthXYPlot height={310} yDomain={[0, yMax]}>
            <VerticalGridLines />
            <HorizontalGridLines/>
            <XAxis/>
            <YAxis/>
            <ChartLabel text="Very Unhealthy" className="alt-x-label" includeMargin={false} xPercent={0.010}
                yPercent={0.075} />
            <ChartLabel text="Unhealthy" className="alt-x-label" includeMargin={false} xPercent={0.010}
                yPercent={0.67} />
            <ChartLabel text="Unhealthy for Sensitive" className="alt-x-label" includeMargin={false} xPercent={0.010}
                yPercent={0.797} />
            <ChartLabel text="Moderate" className="alt-x-label" includeMargin={false} xPercent={0.010}
                yPercent={0.94} />
            <ChartLabel text="Good" className="alt-x-label" includeMargin={false} xPercent={0.010}
                yPercent={1.015} />
            {/* <YAxis title="1-hour PM2.5"/> */}
            {/* <XAxis title="Time"/> */}
            <LineSeries color="#69001A" data={[{x: 0, y: 250.5},{x: dataArr ? dataArr[dataArr.length - 1].x : null, y: 250.5}]} strokeStyle='dashed' strokeWidth='0.5'/>
            <LineSeries color="#85003B" data={[{x: 0, y: 150.5},{x: dataArr ? dataArr[dataArr.length - 1].x : null, y: 150.5}]} strokeStyle='dashed' strokeWidth='0.5'/>
            <LineSeries color="#FC0006" data={[{x: 0, y: 55.5},{x: dataArr ? dataArr[dataArr.length - 1].x : null, y: 55.5}]} strokeStyle='dashed' strokeWidth='0.5'/>
            <LineSeries color="#FC6807" data={[{x: 0, y: 35.5},{x: dataArr ? dataArr[dataArr.length - 1].x : null, y: 35.5}]} strokeStyle='dashed' strokeWidth='0.5'/>
            <LineSeries color="#ffee00" data={[{x: 0, y: 12.1},{x: dataArr ? dataArr[dataArr.length - 1].x : null, y: 12.1}]} strokeStyle='dashed' strokeWidth='1.0'/>
            <LineSeries color="#17797F" data={dataArr}/>
        </FlexibleWidthXYPlot>
    )
}
export default Chart

import React from 'react'
import Chart from './Chart'

export default class HazeGraph extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="haze-pm25-graph">
                <Chart data={this.props.hazeData.items} sourceLocation={this.props.sourceLocation} width={this.state.width}></Chart>
                <div style={{textAlign: 'right', marginRight: '10px'}} className="light">
                    <font size="1">Latest reading: {this.props.hazeData.items ? this.props.hazeData.items[this.props.hazeData.items.length - 1].update_timestamp.substring(11,16) : null}</font>
                </div>
            </div>
        )
    }
}
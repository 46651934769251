import React from 'react'

export default class HazeData extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
    }

    getSeverity (value) {
        var res = {class:'',name:''}
        if (value >= 250.5) res = {class:'hazardous',name:'Hazardous'}
        else if (value >= 150.5) res = {class:'veryunhealthy',name:'Very Unhealthy'}
        else if (value >= 55.5) res = {class:'unhealthy',name:'Unhealthy'}
        else if (value >= 35.5) res = {class:'usg',name:'Unhealthy for Sensitive Groups'}
        else if (value >= 12.1) res = {class:'moderate',name:'Moderate'}
        else if (value >= 1) res = {class:'good',name:'Good'}
        console.log(res)
        return res
    }

    render() {
        const severity = this.getSeverity(this.props.hazeData.items ? this.props.hazeData.items[this.props.hazeData.items.length -1].readings.pm25_one_hourly[this.props.sourceLocation] : null)
        return (
            <div id="HazeData" className={ severity.class }>
                <div style={{paddingTop:'0px', paddingBottom: '0px', fontSize: '24px'}}>
                    {severity.name ? severity.name : "Loading..."}
                </div>
                <div style={{paddingTop:'12px', paddingBottom: '6px', fontSize: '20px'}}>
                    {this.props.hazeData.items ? this.props.hazeData.items[this.props.hazeData.items.length - 1].readings.pm25_one_hourly[this.props.sourceLocation] : "..."}
                </div>
            </div>
        )
    }
}
import React, { Component } from 'react'
import HazeData from './HazeData'
import HazeGraph from './HazeGraph'
import ErrorBoundary from './ErrorBoundary'
import axios from 'axios'
import * as firebase from "firebase/app"
import "firebase/analytics"
import "firebase/performance"
import './App.css'

const sourceLocations=['North', 'East', 'South', 'West', 'Central']

class App extends Component {
  constructor(props){
    super(props)
    this.state = {
      hazeData: [],
      currentSourceIndex: 2,
      error: null
    }

    const firebaseConfig = {
      apiKey: "AIzaSyDE9pb33QxC_k9Ha0FUzvHr3Dsmw1GIOn0",
      authDomain: "hazelah-8490b.firebaseapp.com",
      databaseURL: "https://hazelah-8490b.firebaseio.com",
      projectId: "hazelah-8490b",
      storageBucket: "",
      messagingSenderId: "617944351109",
      appId: "1:617944351109:web:2b34710a14f147aa78c4ec",
      measurementId: "G-WNPP284L80"
    }
    firebase.initializeApp(firebaseConfig)
    const perf = firebase.performance()
    this.refresh = this.refresh.bind(this)
    this.cycleSourceLocation = this.cycleSourceLocation.bind(this)

    const trace = perf.trace('fetchPM25Data()');
    trace.start();
    // code that you want to trace
    this.fetchPM25Data()
    trace.stop();
  }


  componentDidMount(){
    window.addEventListener("focus", this.handleVisibilityChange)
    // document.addEventListener('visibilitychange', this.handleVisibilityChange, false);
  }


  fetchPM25Data() {
    var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1); // => '2015-01-26T06:40:36.181'
    const url = 'https://api.data.gov.sg/v1/environment/pm25?date=' + localISOTime.substring(0,10)
    // const url = 'https://api.data.gov.sg/v1/environment/pm25?date=2019-11-08'
    console.log(url)
    axios.get(url)
      .then(res => {
        // Validate API response:
        if (res.data.items.length === 0) {
          throw new Error("API response has no data today!")
        }

        this.setState({ hazeData: res.data })
        console.log(sourceLocations[this.state.currentSourceIndex] + ': ' + this.state.hazeData.items[this.state.hazeData.items.length - 1].readings.pm25_one_hourly[sourceLocations[this.state.currentSourceIndex].toLowerCase()])
      })
      // TODO: https://css-tricks.com/using-data-in-react-with-the-fetch-api-and-axios/
      // .catch(error => this.setState({ error, isLoading: false }));
      .catch(error => {
        console.log("ERROR - AXIOS: " + error)
        this.setState({ error})}
        )
    }

  cycleSourceLocation()
  {
    let i = this.state.currentSourceIndex
    if(i++ >= sourceLocations.length -1) {
      i=0
    }
    this.setState({currentSourceIndex: i})
    console.log('this.state.currentSourceIndex: ' + this.state.currentSourceIndex)
  }

  refresh() {
    this.setState({ hazeData: [] }) // TODO: progress indicator
    this.fetchPM25Data()
  }

  handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      console.log('visible - Refreshing on focus')
      this.refresh()
    } else {
      console.log('hidden')
    }
  }


  render() {
    // const { isLoading, error } = this.state;
    const { error } = this.state;
    return(
      <div className="App">
        {error ? <div><h2>{'Sorry, data.gov.sg API failed: '}</h2>{error.message}</div> : null}
        <ErrorBoundary>
          <div onClick={this.cycleSourceLocation}>
            <h2>Singapore - {sourceLocations[this.state.currentSourceIndex]}</h2>
          </div>

          <div onClick={this.refresh}>
            <h3><HazeData id="hazeData" hazeData={this.state.hazeData} sourceLocation={sourceLocations[this.state.currentSourceIndex].toLowerCase()}/></h3> 
          </div>

          <div style={{height: '27px'}}>
            <small>1-hour PM2.5</small>
          </div>

          <div id="HazeGraph">
            <HazeGraph hazeData={this.state.hazeData} sourceLocation={sourceLocations[this.state.currentSourceIndex].toLowerCase()}/>
          </div>

          <div id="references">
            <p id="citation"><cite>"...the main air pollutant during the haze season is PM2.5. Hence, the 1-hour PM2.5 concentration readings are a good indicator of the <b>current air quality</b>. Use this for immediate activities like going for a jog."</cite></p>
            <p id="datasource">Source: <a href="https://www.haze.gov.sg">www.haze.gov.sg</a></p>
            <table id="bands">
              <thead>
                <tr><th>Revised Breakpoints<br></br>PM<sub>2.5</sub> (µg/m<sup>3</sup>)</th><th>U.S. EPA AQI Category</th></tr>
              </thead>
              <tbody>
                <tr><td>250.5-500.4</td><td className='hazardous light'>Hazardous</td></tr>
                <tr><td>150.5-250.4</td><td className='veryunhealthy light'>Very Unhealthy</td></tr>
                <tr><td>55.5-150.4</td><td className='unhealthy light'>Unhealthy</td></tr>
                <tr><td>35.5-55.4</td><td className='usg light'>Unhealthy for Sensitive</td></tr>
                <tr><td>12.1-35.4</td><td className='moderate light'>Moderate</td></tr>
                <tr><td>0.0-12.0</td><td className='good light'>Good</td></tr>
              </tbody>
            </table>
            <p id="datasource">Source: <a href="https://www.epa.gov/sites/production/files/2016-04/documents/2012_aqi_factsheet.pdf">www.epa.gov</a></p>
          </div>

          {/* <iframe ref='pm25' title="PM2.5" width="100%" height="420" src={pm25url} frameBorder="0"> </iframe> */}

          {/* <h2>24h PSI</h2>
          <iframe title="PSI 24h" width="100%" height="400" src="https://data.gov.sg/dataset/psi/resource/82776919-0de1-4faf-bd9e-9c997f9a729d/view/5897450a-1536-40a2-953e-2c8981347b0c" frameBorder="0"> </iframe> */}

      </ErrorBoundary>
      </div>
    )
  }
}

export default App
